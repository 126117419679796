body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* viewportの高さを100%に設定 */
}

main {
  flex: 1; /* mainコンテンツが余白を埋める */
}

.pl-5 {
  padding-left: 3rem!important;
}

.primary {
  color: #cf0100 !important;
}

.bg-primary {
  background-color: #cf0100 !important;
  color: white !important;
}

.border-primary {
  border-color: #cf0100 !important;
}

.secondary {
  color: #292929 !important;
}

.bg-secondary {
  background-color: #292929 !important;
  color: white !important;
}

.border-secondary {
  border-color: #292929;
}

.previous {
  color: #5e5e5e !important;
}

.bg-previous {
  background-color: #5e5e5e !important;
}

.border-previous {
  border-color: #5e5e5e;
}

.non-available {
  color: #b0b0b0 !important;
}

.bg-non-available {
  background-color: #b0b0b0 !important;
}

.border-non-available {
  border-color: #b0b0b0;
}

.postBanner {
  border-left: 12px solid !important;
  padding: 40px 0px 40px 40px;
}

.list-group-item {
  border: none;
}

.logo {
  width: 64px;
  height: auto;
}

.btn {
  border-radius: 0 !important;
  font-size: x-large;
}

.w-240 {
  width: 240px;
}

.w-180 {
  width: 180px;
}

.btn-postBanner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-hover:hover {
  opacity: 80%;
  transition: all 0.3s;
}

.btn-postBanner:hover {
  opacity: 80%;
  transition: all 0.3s;
}

.pic-responsive {
  max-width: 100%;
  height: auto;
}

.title-font {
  font-family: 'Holtwood One SC', serif;
}

.parent-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.section-selector {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.dia-line {
  transform: rotate(14.78deg);
  width: 16px;
  height: 120px;
  position: absolute;
  left: 25%;
}

.runner-name {
  border-bottom: 2px solid #fff;
  text-align: left;
}
.runner-detail {
  text-align: left;
  font-size: medium;
}

.img-center {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left;
}

.list-group-item-100 {
  height: 100px;
  overflow: auto; /* もし内容が高さを超える場合にスクロールを可能にする */
}


.runner-img {
  background-color: #fff;
}

@media (max-width: 576px) {
  .runner-img {
      width: 100%;
      height: auto;
  }
}

.runner-icon {
  margin: 16px;
  max-height: 60px;
  object-fit: contain;
}

.profile-image-card {
  width: 100%;
  transition: transform 0.3s;
}

.slick-center .profile-image-card {
  transform: scale(1.0);
}

.slick-slide:not(.slick-center) .profile-image-card {
  transform: scale(0.65);
  filter: grayscale(70%);  /* オプション: グレースケールを適用 */
}

.carousel-container {
  padding: 0 10%;
  overflow: hidden;
}

.carousel-container .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.carousel-image {
  display: block;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: cover;
}

.carousel-container a {
  outline: none;      /* アウトラインを無効にする */
  border: none;       /* 枠線を無効にする */
}

.carousel-container a:focus, .carousel-container a:active {
  outline: none;      /* フォーカスやアクティブ時のアウトラインを無効にする */
  border: none;       /* フォーカスやアクティブ時の枠線を無効にする */
}

.carousel-container img {
  pointer-events: none;  /* 画像上のマウスイベントを無効にする（オプション） */
}

@keyframes sportyBackground {
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 100%; }
}

.h3-moving-bg {
  background: linear-gradient(45deg, #cf0100, #d08f8f, #cf0100);
  background-size: 300% 300%;
  animation: sportyBackground 3s infinite;
  color: white;
  display: inline-block;
  padding: 10px 20px;
}

.full-size-image {
  width: 100%;      /* 画像の幅を親要素いっぱいにします */
  height: 100%;     /* 画像の高さを親要素いっぱいにします */
  object-fit: cover; /* 画像の縦横比を維持しつつ、親要素をカバーするように表示します */
}

.custom-border {
  border: 5px solid #cf0100; /* 外側の赤の境界線 */
  padding: 5px; /* このパディングが白い境界線として機能します */
  background-color: white;
  position: relative;
}

.custom-border::before {
  content: "";
  position: absolute;
  top: 5px; 
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid rgb(255, 255, 255); /* 最内部の赤の境界線 */
}

.btn-custom-border {
  border: 3px solid #cf0100; /* 外側の赤の境界線 */
  padding: 10px; /* このパディングが白い境界線として機能します */
  background-color: white;
  position: relative;
}

.btn-custom-border::before {
  content: "";
  position: absolute;
  top: 3px; 
  right: 3px;
  bottom: 3px;
  left: 3px;
  border: 3px solid rgb(255, 255, 255); /* 最内部の赤の境界線 */
}

.ww span {
  display: inline-block;
}