@keyframes raceAnimation {
  0%,
  100% {
    transform: translate(-80px, -5px);
  }
  50% {
    transform: translate(50px, -5px); /* 左右50px移動 */
  }
}

@keyframes reverseRaceAnimation {
  0%,
  100% {
    transform: translate(50px, 5px);
  }
  50% {
    transform: translate(-40px, 5px); /* 左右50px移動 */
  }
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading-image {
  position: relative;
  width: 200px; /* 画像のサイズに応じて調整 */
  height: 200px; /* 画像のサイズに応じて調整 */
}

.image1 {
  animation: raceAnimation 3s infinite;
}

.image2 {
  animation: reverseRaceAnimation 2s infinite;
}

.loading-container::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48%;
    background-color: grey;
    z-index: -1;
}

.loading-message {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .loading-message span {
    animation-name: dotPulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  
  .loading-message span:nth-child(1) {
    animation-delay: 0.5s;
  }
  
  .loading-message span:nth-child(2) {
    animation-delay: 1s;
  }
  
  .loading-message span:nth-child(3) {
    animation-delay: 1.5s;
  }
  
  @keyframes dotPulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }